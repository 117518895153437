import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Category,
  Container,
  Content,
  LogoCompany,
  LogoTeddy,
  Option,
  OptionLink,
  Options,
} from './styles';

import { SideMenuData } from '../../utils/data';
import LogoTeddyImg from '../../assets/svg/teddy.svg';
import LogoTeddyOpenBanking from '../../assets/svg/teddy-open-banking.svg';
import { FadeInAnimation } from '../../utils/variants';
import { MenuContext } from '../../contexts';

const SideMenu: React.FC = () => {
  const location = useLocation();
  const { isOpen, setIsOpen, isMobile } = useContext(MenuContext);
  return (
    <Container>
      <motion.div
        variants={FadeInAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{ width: '280px' }}
      >
        <Link to="/dashboard" onClick={() => isMobile && setIsOpen(false)}>
          <LogoCompany>
            <img src={LogoTeddyOpenBanking} alt="Teddy Open Banking" />
          </LogoCompany>
        </Link>
        <LogoTeddy>
          {/* <span>by</span>
          <a href="https://teddydigital.io/" target="_blank" rel="noreferrer">
            <img src={LogoTeddyImg} alt="Teddy" />
          </a> */}
        </LogoTeddy>
      </motion.div>
      <Content
        variants={FadeInAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {SideMenuData.map(({ icon: Icon, ...SideMenuCategory }) => (
          <Category
            className={
              SideMenuCategory.features
                .map(itens => itens.path)
                .includes(location.pathname)
                ? 'active-router'
                : 'unset'
            }
            disabled={SideMenuCategory.disabled}
            key={SideMenuCategory.id}
          >
            <div>
              <Icon boxSize={6} />
              <h2 key={SideMenuCategory.id}>{SideMenuCategory.name}</h2>
            </div>
            <Options disabled={SideMenuCategory.disabled}>
              {SideMenuCategory.features.map(SideMenuOption => (
                <>
                  {SideMenuOption.redirect && (
                    <OptionLink
                      className={
                        location.pathname.includes(SideMenuOption.path)
                          ? 'active-router'
                          : 'unset'
                      }
                      href={SideMenuOption.path}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => isMobile && setIsOpen(false)}
                      disabled={SideMenuOption.disabled}
                      key={SideMenuOption.name}
                    >
                      {SideMenuOption.name}
                    </OptionLink>
                  )}
                  {!SideMenuOption.redirect && (
                    <Option
                      className={
                        location.pathname.includes(SideMenuOption.path)
                          ? 'active-router'
                          : 'unset'
                      }
                      to={SideMenuOption.path}
                      onClick={() => isMobile && setIsOpen(false)}
                      disabled={SideMenuOption.disabled}
                      key={SideMenuOption.name}
                    >
                      {SideMenuOption.name}
                    </Option>
                  )}
                </>
              ))}
            </Options>
          </Category>
        ))}
      </Content>
    </Container>
  );
};

export default SideMenu;
