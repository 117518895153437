import React, { useContext } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { IoReload } from 'react-icons/io5';
import { FaChevronLeft } from 'react-icons/fa';
import {
  Container,
  Content,
  Header,
  RouterActive,
  SpacerHeader,
} from './styles';
import { InfoBadge } from '..';
import { BoxContext } from '../../contexts';

interface Props {
  title: string;
  description?: string;
  breadcrumbs?: boolean;
  backButton?: boolean;
  actionButton?: boolean;
  reloadButton?: boolean;
}

const Box: React.FC<Props> = ({
  title,
  description,
  breadcrumbs = false,
  backButton = false,
  actionButton = false,
  reloadButton = false,
  children,
}) => {
  const {
    titleButton,
    pathButton,
    titleBreadcrumb,
    pathBreadcrumb,
    reloadAction,
  } = useContext(BoxContext);
  const history = useHistory();
  return (
    <Container>
      <Header>
        <SpacerHeader>
          {backButton && (
            <Tooltip label="Voltar">
              <IconButton
                variant="ghost"
                aria-label="Back Table"
                icon={<FaChevronLeft />}
                onClick={history.goBack}
              />
            </Tooltip>
          )}
          <h3>{title}</h3>
          {description && <InfoBadge title={title} description={description} />}
          {reloadButton && reloadAction && (
            <Tooltip label="Recarregar">
              <IconButton
                variant="ghost"
                aria-label="Reload Table"
                icon={<IoReload />}
                onClick={reloadAction}
              />
            </Tooltip>
          )}
        </SpacerHeader>
        <SpacerHeader>
          {actionButton && titleButton && pathButton && (
            <Link to={pathButton}>
              <Button colorScheme="success">{titleButton}</Button>
            </Link>
          )}
          {breadcrumbs && titleBreadcrumb && pathBreadcrumb && (
            <Breadcrumb separator="|" className="breadcrumb">
              <BreadcrumbItem>
                <BreadcrumbLink onClick={() => history.replace(pathBreadcrumb)}>
                  {titleBreadcrumb}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <RouterActive>{title}</RouterActive>
              </BreadcrumbItem>
            </Breadcrumb>
          )}
        </SpacerHeader>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default Box;
