import { extendTheme } from '@chakra-ui/react';

export const Theme = extendTheme({
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
  colors: {
    primary: {
      50: '#fdefe8',
      100: '#f9ceb9',
      200: '#f5ad8a',
      300: '#f18d5b',
      400: '#ed6c2c',
      500: '#eb6625',
      600: '#d35312',
      700: '#a4400e',
      800: '#752e0a',
      900: '#461c06',
    },
    secondary: {
      50: '#c1d2f0',
      100: '#98b5e7',
      200: '#4579d3',
      300: '#2c60ba',
      400: '#2755a5',
      500: '#1e4280',
      600: '#183567',
      700: '#132b53',
      800: '#0f203e',
      900: '#0a1529',
    },
    success: {
      50: '#e8fdf5',
      100: '#b9f9e0',
      200: '#8af4cd',
      300: '#5cf0ba',
      400: '#16e99c',
      500: '#11b87a',
      600: '#0fa36d',
      700: '#0d8c5d',
      800: '#095d3e',
      900: '#042f1f',
    },
    error: {
      50: '#fde7e7',
      100: '#f9b8b8',
      200: '#f7a1a1',
      300: '#f58989',
      400: '#f47171',
      500: '#F04C4C',
      600: '#ee2b2b',
      700: '#d41111',
      800: '#a50d0d',
      900: '#5e0808',
    },
  },
});
