/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiData, ApiStatus } from '../types';
import { DataUsers } from './data';
import {
  ConfigContextData,
  EditUserRequest,
  ListUsersResponse,
  MyProfileRequest,
  MyProfileResponse,
  NewUserRequest,
  UserResponse,
} from './types';

export class AdapterConfigService implements ConfigContextData {
  async getUser(id: string): Promise<ApiData<UserResponse>> {
    const { data } = await api.get<{
      id: string;
      firstName: string;
      lastName: string;
      profileId: string;
      email: string;
      cnpj: string;
      phone: string;
      cellPhone: string;
      status: string;
      createdAt: Date;
      updatedAt: Date;
    }>(`/users/${id}`);

    return {
      error: false,
      data: {
        id: data.id,
        firstName: data.firstName,
        email: data.email,
        lastName: data.lastName,
        cnpj: data.cnpj,
        cellPhone: data.cellPhone,
        phone: data.phone,
        status: data.status,
        profileId: data.profileId,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      },
    };
  }

  async myProfile(): Promise<ApiData<MyProfileResponse>> {
    const { data } = await api.get<{
      id: string;
      cnpj: string;
      firstName: string;
      lastName: string;
      profileId: string;
      email: string;
      phone: string;
      cellPhone: string;
      status: string;
      createdAt: Date;
    }>(`my-profile`);

    return {
      error: false,
      data: {
        id: data.id,
        cnpj: data.cnpj,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        profileId: data.profileId,
        cellPhone: data.cellPhone,
        phone: data.phone,
        status: data.status,
        createdAt: data.createdAt,
      },
    };
  }

  async listUsers(): Promise<ApiData<ListUsersResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        firstName: string;
        lastName: string;
        profileId: string;
        email: string;
        cnpj: string;
        phone: string;
        cellPhone: string;
        status: string;
        createdAt: Date;
        updatedAt: Date;
      }[]
    >(`/users`);

    const companies = await api.get<
      {
        cnpj: string;
        name: string;
      }[]
    >('/companies');

    return {
      error: false,
      data: data.map(
        ({
          cellPhone,
          email,
          firstName,
          id,
          cnpj,
          lastName,
          phone,
          profileId,
          status,
          createdAt,
          updatedAt,
        }) => {
          return {
            id,
            firstName,
            email,
            lastName,
            cnpj,
            company:
              companies.data.find(company => company.cnpj === cnpj)?.name ||
              'Desconhecido',
            cellPhone,
            phone,
            status,
            profileId,
            createdAt,
            updatedAt,
          };
        },
      ),
    };
  }

  async deleteUser(id: string): Promise<ApiStatus> {
    const response = await api.delete(`/users/${id}`)
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  async editUser(
    id: string,
    { firstName, lastName, email, password, status, phone, cellPhone }: EditUserRequest,
  ): Promise<ApiStatus> {
    const response = await api.patch(`/users/${id}`, {
      firstName,
      lastName,
      email,
      password,
      status,
      phone: String(phone),
      cellPhone: String(cellPhone)

    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  newUser(newUserRequest: NewUserRequest): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }

  editMyProfile(myProfileRequest: MyProfileRequest): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }



  async changePasswordUser(
    id: string,
    { newPassword }: { newPassword: string }
  ): Promise<ApiStatus> {
    const response = await api.patch(`/users/${id}/password`, {
     newPassword

    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }
}
