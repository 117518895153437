import React, { createContext, useContext, useMemo } from 'react';
import { AdapterConfigService } from './adapter.config.service';
import { FakerConfigService } from './faker.config.service';
import { ConfigContextData } from './types';

const ConfigContext = createContext<ConfigContextData>({} as ConfigContextData);

const factoryConfigService = (): ConfigContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterConfigService();
    }
    return new FakerConfigService();
  }
  return new AdapterConfigService();
};

export const ConfigProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryConfigService(), []);

  return (
    <ConfigContext.Provider value={service}>{children}</ConfigContext.Provider>
  );
};

export function useConfig(): ConfigContextData {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within an ConfigProvider');
  }

  return context;
}
