import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import UserEdit from './pages/user-edit';
import UserList from './pages/user-list';

const Users: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <Switch key={location.key}>
        <Route exact path="/usuarios" component={UserList} />
        <Route exact path="/usuarios/editar/:id" component={UserEdit} />
        <Redirect to="/dashboard" exact />
      </Switch>
    </>
  );
};

export default Users;
