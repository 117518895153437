import api from '../api';
import { GroupPermissionEntity, ProfileEntity } from '../plan/models';

export const getAllPermissions = async function (): Promise<
  GroupPermissionEntity[]
> {
  try {
    const { data: result } = await api.get<
      {
        id: string;
        name: string;
        permissions: {
          code: string;
          name: string;
        }[];
      }[]
    >('/permissions');

    return result.map(group => ({
      id: group.id,
      name: group.name,
      permissions: group.permissions.map(profile => ({
        id: profile.code,
        name: profile.name,
      })),
    }));
  } catch {
    return [
      {
        id: 'teddy-credit',
        name: 'Crédito',
        permissions: [
          {
            id: 'credit-relatorios',
            name: 'Relatório de Crédito',
          },
          {
            id: 'credit-monitore',
            name: 'Monitore',
          },
        ],
      },
      {
        id: 'teddy-serasa',
        name: 'Serasa',
        permissions: [
          {
            id: 'serasa-consulta',
            name: 'Consulta Serasa',
          },
          {
            id: 'serasa-perfin',
            name: 'Perfin',
          },
        ],
      },
      {
        id: 'teddy-cliente',
        name: 'Configurações',
        permissions: [
          {
            id: 'tc-manager-users',
            name: 'Gestão de Usuários',
          },
          {
            id: 'tc-manager-profiles',
            name: 'Gestão de Perfis',
          },
        ],
      },
    ];
  }
};

export const parsePermissionsCodeToGroupPermissions = async function (
  permissionsCode: string[],
): Promise<GroupPermissionEntity[]> {
  const groupPermisisons = await getAllPermissions();
  return groupPermisisons.map(group => {
    const parsedGroup = {
      ...group,
      permissions: group.permissions.filter(vl =>
        permissionsCode.includes(vl.id),
      ),
    };
    return parsedGroup;
  });
};

export const parsePermissionsCodeToGroupPermissionsUnique = async function (
  groupPermisisons: GroupPermissionEntity[],
  permissionsCode: string[],
): Promise<GroupPermissionEntity[]> {
  return groupPermisisons.map(group => {
    const parsedGroup = {
      ...group,
      permissions: group.permissions.filter(vl =>
        permissionsCode.includes(vl.id),
      ),
    };
    return parsedGroup;
  });
};

export const adapterProfileParse = async (
  profiles: any[],
): Promise<ProfileEntity[]> => {
  const groups = await getAllPermissions();
  const result = await Promise.all(
    profiles.map(async profile => ({
      id: profile.id,
      name: profile.name,
      groupPermissions: await parsePermissionsCodeToGroupPermissions(
        profile.permissions,
      ),
    })),
  );
  return result;
};

export const parseIdToProfile = (
  groupPermissions: {
    id: string;
    permissions: {
      id: string;
    }[];
  }[],
): string[] => {
  const permissions: string[] = [];
  groupPermissions.forEach(groupPermission => {
    const group = groupPermission.permissions.map(permissionInternal =>
      String(permissionInternal.id),
    );
    permissions.push(...group);
  });
  return permissions;
};
