/* eslint-disable dot-notation */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { AuthContextData } from './types';
import { appPrefix } from './constants';
import api from '../../services/api';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState({});
  const { keycloak, initialized } = useKeycloak();
  const [hasToken, setHasToken] = useState(false);
  const [expirationTime, setExpirationTime] = useState<number | undefined>();

  useEffect(() => {
    setHasToken(false);
    if (!initialized) {
      return;
    }

    if (
      initialized &&
      keycloak.authenticated &&
      keycloak.realmAccess &&
      !keycloak.realmAccess.roles.includes('admin')
    ) {
      keycloak.logout();
    }

    const fetchUserInfo = async () => {
      const userProfile = await keycloak.loadUserProfile();
      setUser({
        ...userProfile,
        fullName: `${userProfile.firstName} ${userProfile.lastName}`,
      });
      localStorage.setItem(
        `${appPrefix}:user`,
        JSON.stringify({
          ...userProfile,
          fullName: `${userProfile.firstName} ${userProfile.lastName}`,
        }),
      );
      api.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
      setHasToken(true);
    };

    if (
      initialized &&
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.exp
    ) {
      fetchUserInfo();
      setExpirationTime(keycloak.tokenParsed.exp * 1000);
    }
  }, [keycloak, initialized]);

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  const logout = useCallback(() => {
    keycloak.logout().then(() => {
      localStorage.removeItem(`${appPrefix}:user`);
      setHasToken(false);
    });
  }, [keycloak]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!keycloak.authenticated,
        initialized,
        token: keycloak.token,
        hasToken,
        setHasToken,
        user,
        roles: keycloak.realmAccess,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
