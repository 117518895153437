import React, { createContext, useContext, useMemo } from 'react';
import { AdapterProfileService } from './adapter.profile.service';
import { FakerProfileService } from './faker.profile.service';
import { ProfileContextData } from './types';

const ProfileContext = createContext<ProfileContextData>(
  {} as ProfileContextData,
);

const factoryProfileService = (): ProfileContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterProfileService();
    }
    return new FakerProfileService();
  }
  return new AdapterProfileService();
};

export const ProfileProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryProfileService(), []);

  return (
    <ProfileContext.Provider value={service}>
      {children}
    </ProfileContext.Provider>
  );
};

export function useProfile(): ProfileContextData {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within an ProfileProvider');
  }

  return context;
}
