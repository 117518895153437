import React from 'react';

import { Global } from '@emotion/react';

export const Fonts = (): JSX.Element => (
  <Global
    styles={`
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
      `}
  />
);
