import React, { createContext, useContext, useMemo } from 'react';
import { AdapterReportsService } from './adapter.reports.service';
import { FakerReportsService } from './faker.reports.service';
import { ReportsContextData } from './types';

const ReportsContext = createContext<ReportsContextData>(
  {} as ReportsContextData,
);

const factoryReportsService = (): ReportsContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterReportsService();
    }
    return new FakerReportsService();
  }
  return new AdapterReportsService();
};

export const ReportsProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryReportsService(), []);

  return (
    <ReportsContext.Provider value={service}>
      {children}
    </ReportsContext.Provider>
  );
};

export function useReports(): ReportsContextData {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReports must be used within an ReportsProvider');
  }

  return context;
}
