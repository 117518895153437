import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Fonts, Theme } from './styles/chakra';
import GlobalStyle from './styles/global';
import Routes from './routes';

function App(): JSX.Element {
  return (
    <>
      <GlobalStyle />
      <ChakraProvider theme={Theme}>
        <Fonts />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
