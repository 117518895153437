import { Skeleton as ChakraSkeleton, SkeletonProps } from '@chakra-ui/react';
import React from 'react';

const Skeleton: React.FC<SkeletonProps> = ({ children, ...rest }) => {
  return (
    <ChakraSkeleton
      startColor="#F1F4F7"
      endColor="#EAF2F6"
      borderRadius={8}
      {...rest}
    >
      {children}
    </ChakraSkeleton>
  );
};

export default Skeleton;
