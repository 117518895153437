/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, useToast } from '@chakra-ui/react';
import { EmailIcon, LockIcon, PhoneIcon } from '@chakra-ui/icons';
import { FaUser } from 'react-icons/fa';

import { Formik, FormikState } from 'formik';
import {
  AnimatedContainer,
  Box,
  ChakraInput,
  ChakraSelect,
} from '../../../../components';
import { BoxContext } from '../../../../contexts';
import schema from './validators/schema';

import { useConfig } from '../../../../services/config';
import { UserResponse } from '../../../../services/config/types';
import schemaPassword from './validators/schema-password';

interface RouteParams {
  id: string;
}

const UserEdit: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserResponse | undefined>();
  const { editUser, getUser, changePasswordUser, deleteUser } = useConfig();
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams<RouteParams>();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const onDeleteUser = async (userId: string) => {
    try {
      await deleteUser(userId)
      toast({
        title: 'Usuário deletado  com sucesso',
        status: 'success',
        duration: 2500,
        isClosable: true,
      });
      history.goBack();
    } catch (error) {
      toast({
        title: 'Falha na Esclusão do Usuário',
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  }
  useEffect(() => {
    getUser(id)
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        toast({
          title: 'Falha no Carregamento do Usuário',
          status: 'error',
          duration: 2500,
          isClosable: true,
        });
        history.goBack();
      });
  }, []);

  const showToast = (
    status: string,
    resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void,
  ) => {
    if (status === 'success') {
      setLoading(false);
      toast({
        title: 'Usuário editado com sucesso',
        status: 'success',
        duration: 2500,
        isClosable: true,
      });
      resetForm();
      history.goBack();
    } else {
      setLoading(false);
      toast({
        title: 'Falha na Edição do Usuário',
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Helmet title="Editar Usuário - RiskCred by Teddy®" />
      <AnimatedContainer>
        <BoxContext.Provider
          value={{
            titleBreadcrumb: 'Usuários',
            pathBreadcrumb: `/usuarios`,
          }}
        >
          <Box title="Editar Usuário" backButton breadcrumbs>
            <Formik
              enableReinitialize
              initialValues={
                user
                  ? {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      email: user.email,
                      cellPhone: user.cellPhone,
                      phone: user.phone,
                      status: user.status,
                    }
                  : {
                      firstName: '',
                      lastName: '',
                      email: '',
                      cellPhone: '',
                      phone: '',
                      status: '',
                    }
              }
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                try {
                  const response = await editUser(id, values);
                  if (!response.error) {
                    showToast('success', resetForm);
                  } else {
                    showToast('error', resetForm);
                  }
                } catch (error) {
                  showToast('error', resetForm);
                }
              }}
            >
              {({ handleSubmit, resetForm }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ChakraInput
                        name="firstName"
                        label="Nome"
                        placeholder="Digite o Nome"
                        icon={FaUser}
                        isLoading={user === undefined}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChakraInput
                        name="lastName"
                        label="Sobrenome"
                        placeholder="Digite o Sobrenome"
                        icon={FaUser}
                        isLoading={user === undefined}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChakraInput
                        name="email"
                        label="E-mail"
                        placeholder="Digite o E-mail"
                        icon={EmailIcon}
                        type="email"
                        isLoading={user === undefined}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChakraInput
                        name="phone"
                        label="Telefone"
                        placeholder="Digite o Telefone"
                        icon={PhoneIcon}
                        type="number"
                        isLoading={user === undefined}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChakraInput
                        name="cellPhone"
                        label="Celular"
                        placeholder="Digite o Celular"
                        icon={PhoneIcon}
                        type="number"
                        isLoading={user === undefined}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ChakraSelect
                        name="status"
                        label="Status"
                        placeholder="Selecione"
                        isLoading={user === undefined}
                      >
                        <option value="Ativo">Ativo</option>
                        <option value="Inativo">Inativo</option>
                      </ChakraSelect>
                    </Grid>
                    <Grid item xs={6} md={3} />
                    <Grid item xs={6} md={3} />
                    <Grid item xs={6} md={3}>
                      <Button
                        width="100%"
                        colorScheme="red"
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Button
                        type="submit"
                        width="100%"
                        colorScheme="success"
                        isLoading={loading}
                        disabled={user === undefined}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
          <Box title="Editar Senha do Usuário" backButton breadcrumbs>
            <Formik
              enableReinitialize
              initialValues={
                {
                  newPassword: ''
                }
              }
              validationSchema={schemaPassword}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                try {
                  const response = await changePasswordUser(id, values);
                  if (!response.error) {
                    showToast('success', resetForm);
                  } else {
                    showToast('error', resetForm);
                  }
                } catch (error) {
                  showToast('error', resetForm);
                }
              }}
            >
              {({ handleSubmit, resetForm }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ChakraInput
                        name="newPassword"
                        label="Nova Senha"
                        placeholder="Digite a nova senha"
                        icon={LockIcon}
                        isLoading={user === undefined}
                        password
                      />
                    </Grid>
                   
                    <Grid item xs={6} md={3} />
                    <Grid item xs={6} md={3} />
                    <Grid item xs={6} md={3}>
                      <Button
                        width="100%"
                        colorScheme="red"
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Button
                        type="submit"
                        width="100%"
                        colorScheme="success"
                        isLoading={loading}
                        disabled={user === undefined}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
          <Box title="Excluir Usuário" backButton breadcrumbs>
                <Alert 
                  status='error' 
                  variant='subtle'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  textAlign='center'
                  width="50%"
                >
                  <AlertIcon boxSize='40px' mr={0}/>
                  <AlertTitle mt={4} mb={1} fontSize='lg'>Tem certeza que deseja excluir o Usuário?</AlertTitle>
                  <AlertDescription maxWidth='sm'  mt={4} mb={1}>
                    {
                      confirmDelete ? 
                      <Button
                        type="submit"
                        onClick={() => onDeleteUser(id)}
                        width="100%"
                        colorScheme="orange"
                        isLoading={loading}
                        disabled={user === undefined}
                      >
                        Excluir
                      </Button>
                      :
                      <Button 
                       
                        type="submit"
                        onClick={() => setConfirmDelete(true) }
                        width="100%"
                        colorScheme="orange"
                        isLoading={loading}
                        disabled={user === undefined}
                      >
                        Continuar
                      </Button>
                    }
                  </AlertDescription>
                </Alert>
          </Box>
        </BoxContext.Provider>
      </AnimatedContainer>
    </>
  );
};

export default UserEdit;
