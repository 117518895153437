import React, { createContext, useContext, useMemo } from 'react';
import { AdapterPlanService } from './adapter.plan.service';
import { FakerPlanService } from './faker.plan.service';
import { PlanContextData } from './types';

const PlanContext = createContext<PlanContextData>({} as PlanContextData);

const factoryPlanService = (): PlanContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterPlanService();
    }
    return new FakerPlanService();
  }

  return new AdapterPlanService();
};

export const PlanProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryPlanService(), []);

  return (
    <PlanContext.Provider value={service}>{children}</PlanContext.Provider>
  );
};

export function usePlan(): PlanContextData {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within an PlanProvider');
  }

  return context;
}
