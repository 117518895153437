/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import Axios, { AxiosError } from 'axios';
import api from '../api';
import { ProfileEntity } from '../profile/models';
import { ApiData, ApiStatus } from '../types';
import { DataCompany } from './data';
import { CompanyEntity } from './models';
import {
  CompanyContextData,
  CompanyRequest,
  EditCompanyRequest,
} from './types';

export class AdapterCompanyService implements CompanyContextData {
  async uploadPicture(cnpj: string, picture: FormData): Promise<ApiStatus> {
    const response = await api.put(`/companies/${cnpj}/image`, picture);
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  async listProfiles(planId: string): Promise<ApiData<ProfileEntity[]>> {
    const { data } = await api.get<
      {
        id: string;
        name: string;
        permissions: string[];
      }[]
    >(`/companies/${planId}/profiles`);
    return {
      error: false,
      data: data.map(({ id, name }) => {
        const result = {
          id,
          name,
          groupPermissions: [],
          planId,
          date: new Date(),
        };
        return result;
      }),
    };
  }

  async getCompany(id: string): Promise<ApiData<CompanyEntity>> {
    try {
      const { data } = await api.get<{
        cnpj: string;
        name: string;
        planId: string;
        vaduToken?: string;
        logoImage?: string;
        profilesId: string[];
        createdAt: Date;
        socialReason?: string;
        fantasyName?: string;
        address?: {
          complement: string;
          country: string;
          uf: string;
          city: string;
          district: string;
          cep: string;
          line: string;
        };
        contact?: any;
      }>(`/companies/${id}`);

      const {
        cnpj,
        profilesId,
        name,
        planId,
        vaduToken,
        logoImage,
        createdAt,
        socialReason,
        fantasyName,
        address,
        contact,
      } = data;

      return {
        error: false,
        data: {
          cnpj,
          date: createdAt,
          id: cnpj,
          name,
          profiles: profilesId.map(profile => ({
            id: profile,
            name: profile,
          })),
          planId,
          vaduToken,
          logoImage,
          socialReason,
          fantasyName,
          address,
          contact,
        },
      };
    } catch (err) {
      const error = err as AxiosError;
      return {
        error: true,
        message:
          error.response?.status === 406
            ? 'Algum dos dados esta incorreto'
            : 'Ocorreu um erro inesperado!',
      };
    }
  }

  deleteCompany(id: string): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }

  async editCompany(
    id: string,
    {
      vaduToken,
      name,
      planId,
      socialReason,
      fantasyName,
      address,
      contact,
    }: EditCompanyRequest,
  ): Promise<ApiStatus> {
    try {
      const { data: company } = await api.patch(`/companies/${id}`, {
        vaduToken,
        name,
        socialReason,
        fantasyName,
        address,
        contact,
      });
      const { data: plan } = await api.patch(`/companies/${id}/plan`, {
        planId,
      });

      return {
        error: false,
        message: 'Empresa editada',
      };
    } catch (err) {
      const error = err as AxiosError;
      return {
        error: true,
        message:
          error.response?.status === 406
            ? 'Algum dos dados esta incorreto'
            : 'Ocorreu um erro inesperado!',
      };
    }
  }

  async newCompany({
    cnpj,
    name,
    planId,
    vaduToken,
    socialReason,
    fantasyName,
    address,
    contact,
  }: CompanyRequest): Promise<ApiStatus> {
    try {
      const { data } = await api.post('/companies', {
        name,
        cnpj,
        planId,
        vaduToken,
        socialReason,
        fantasyName,
        address,
        contact,
      });

      return {
        error: false,
        message: 'Empresa registrada',
      };
    } catch (err) {
      const error = err as AxiosError;
      return {
        error: true,
        message:
          error.response?.status === 406
            ? 'Algum dos dados esta incorreto'
            : 'Ocorreu um erro inesperado!',
      };
    }
  }

  async listCompanies(): Promise<ApiData<CompanyEntity[]>> {
    const { data } = await api.get<
      {
        cnpj: string;
        planId: string;
        name: string;
        vaduToken: string;
        logoImage?: string;
        profilesId: string[];
        createdAt: Date;
      }[]
    >('/companies');

    return {
      error: false,
      data: data.map(
        ({
          cnpj,
          profilesId,
          name,
          planId,
          vaduToken,
          logoImage,
          createdAt,
        }) => ({
          id: cnpj,
          name,
          cnpj,
          date: createdAt,
          vaduToken: vaduToken === '' ? undefined : vaduToken,
          logoImage,
          planId,
          profiles: profilesId.map(profile => ({
            id: profile,
            name: profile,
          })),
        }),
      ),
    };
  }

  async searchCep(
    cepInput: string,
  ): Promise<
    ApiData<{ cep: string; uf: string; district: string; city: string } | null>
  > {
    try {
      const { data } = await Axios.get<{
        cep: string;
        logradouro: string;
        complemento: string;
        bairro: string;
        localidade: string;
        uf: string;
        ibge: number;
        gia: number;
        ddd: number;
        siafi: string;
      }>(`https://viacep.com.br/ws/${cepInput}/json/`);
      console.log(data);
      const { cep, uf, localidade, bairro } = data;
      return {
        error: false,
        data: {
          cep,
          uf,
          district: bairro,
          city: localidade,
        },
      };
    } catch (error) {
      return {
        error: true,
        data: null,
      };
    }
  }
}
