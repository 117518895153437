import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: var(--rc-color-secondary);
  height: 100vh;
  width: 100vw;

  img {
    max-width: 250px;
    /* filter: brightness(0) invert(1); */
    /* opacity: 0.6; */
  }

  p {
    color: #fff;
  }
`;
