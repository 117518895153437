/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { DataGroupPermissions } from '../plan/data';
import { ApiData, ApiStatus } from '../types';
import { DataProfile } from './data';
import { GroupPermissionEntity, ProfileEntity } from './models';
import {
  getAllPermissions,
  parsePermissionsCodeToGroupPermissions,
} from './profileUtils';
import {
  EditProfileRequest,
  ListProfilesResponse,
  ProfileContextData,
  ProfileRequest,
} from './types';

export class AdapterProfileService implements ProfileContextData {
  async getPermissions(
    planId: string,
  ): Promise<ApiData<GroupPermissionEntity[]>> {
    const { data } = await api.get<{ permissions: string[] }>(
      `/plans/${planId}`,
    );
    return {
      error: false,
      data: await parsePermissionsCodeToGroupPermissions(data.permissions),
    };
  }

  async getProfile(
    planId: string,
    id: string,
  ): Promise<ApiData<ListProfilesResponse>> {
    const { data } = await api.get<
      {
        id: string;
        name: string;
        editable: boolean;
        permissions: string[];
      }[]
    >(`/plans/${planId}/profiles`);
    if (data) {
      const profileSelected = data.find(profile => profile.id === id);
      if (profileSelected) {
        return {
          error: false,
          data: {
            id: profileSelected.id,
            name: profileSelected.name,
            groupPermissions: await parsePermissionsCodeToGroupPermissions(
              profileSelected.permissions,
            ),
            date: new Date(),
          },
        };
      }
      return {
        error: true,
      };
    }
    return {
      error: true,
    };
  }

  deleteProfile(id: string): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }

  async editProfile(
    id: string,
    { id: profileId, name, groupPermissions }: EditProfileRequest,
  ): Promise<ApiStatus> {
    if (groupPermissions) {
      const permissions = groupPermissions
        .map(vl => {
          return vl.permissions.map(permission => permission.id);
        })
        .join(',')
        .split(',')
        .filter(per => per !== '');
      const response = await api.patch(`/plans/${id}`, {
        profiles: [
          {
            id: profileId,
            name,
            permissions,
          },
        ],
      });
      if (response) {
        return {
          error: false,
        };
      }
    }
    return {
      error: true,
    };
  }

  async newProfile(
    planId: string,
    { name, groupPermissions }: ProfileRequest,
  ): Promise<ApiStatus> {
    if (groupPermissions) {
      const permissions = groupPermissions
        .map(vl => {
          return vl.permissions.map(permission => permission.id);
        })
        .join(',')
        .split(',')
        .filter(per => per !== '');
      const response = await api.post(`/plans/${planId}/profiles`, {
        name,
        permissions,
      });
      if (response) {
        return {
          error: false,
        };
      }
    }
    return {
      error: true,
    };
  }

  async listProfiles(planId: string): Promise<ApiData<ListProfilesResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        name: string;
        editable: boolean;
        permissions: string[];
      }[]
    >(`/plans/${planId}/profiles`);
    if (data) {
      const result = await Promise.all(
        data.map(async profile => {
          const groupPermissions = await parsePermissionsCodeToGroupPermissions(
            profile.permissions,
          );
          return {
            id: profile.id,
            name: profile.name,
            groupPermissions,
            date: new Date(),
          };
        }),
      );
      if (result) {
        return {
          error: false,
          data: result,
        };
      }
    }
    return {
      error: true,
    };
  }
}
