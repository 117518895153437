import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useKeycloak } from '@react-keycloak/web';
import PrivateRoute from './privateroute';

import { PageContainer } from '../components';
import Preloader from '../components/Preloader/index';
import Reports from '../pages/reports';
import Users from '../pages/users';

const Auth = lazy(() => import('../pages/auth'));
const Company = lazy(() => import('../pages/company'));
const Config = lazy(() => import('../pages/config'));
const Credit = lazy(() => import('../pages/credit'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Plan = lazy(() => import('../pages/plan'));
const Serasa = lazy(() => import('../pages/serasa'));
const SystemAlert = lazy(() => import('../pages/systemALert'));

const Routes: React.FC = () => {
  const location = useLocation();
  const { initialized } = useKeycloak();
  if (!initialized) {
    return <Preloader />;
  }

  return (
    <>
      {location.pathname !== '/' && location.pathname !== '/login' ? (
        <AnimatePresence>
          <PageContainer>
            <Suspense fallback={<Preloader />}>
              <Switch location={location} key={location.key}>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/config" component={Config} />
                <PrivateRoute path="/credito" component={Credit} />
                <PrivateRoute path="/empresas" component={Company} />
                <PrivateRoute path="/usuarios" component={Users} />
                <PrivateRoute path="/planos" component={Plan} />
                <PrivateRoute path="/serasa" component={Serasa} />
                <PrivateRoute path="/relatorios" component={Reports} />
                <PrivateRoute path="/alerts" component={SystemAlert} />
                <Redirect to="/dashboard" exact />
              </Switch>
            </Suspense>
          </PageContainer>
        </AnimatePresence>
      ) : (
        <Switch location={location} key={location.key}>
          <Suspense fallback={<Preloader />}>
            <Route path="/" component={Auth} />
          </Suspense>
        </Switch>
      )}
    </>
  );
};

export default Routes;
