/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { CellProps, Column } from 'react-table';
import { ReportsResponse } from '../../../../services/reports/types';
import { FormatDate } from '../../../../utils';

export const Columns = () => {
  const columns: Column<ReportsResponse>[] = [
    {
      Header: 'Usuário',
      accessor: 'user',
    },
    {
      Header: 'Empresa',
      accessor: 'company',
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'document',
      Cell: ({ cell }: CellProps<ReportsResponse>) =>
        cnpj.isValid(String(cell.row.values.document))
          ? cnpj.format(String(cell.row.values.document))
          : cpf.format(String(cell.row.values.document)),
    },

    {
      Header: 'Tipo',
      accessor: 'type',
      Cell: ({ cell }: CellProps<ReportsResponse>) => {
        switch (cell.row.values.type) {
          case 'perfin':
            return 'Perfin';
          case 'credit-consult':
            return 'Consulta de Crédito';
          case 'credit-monitore':
            return 'Monitore';
          default:
            return cell.row.values.type;
        }
      },
    },
    {
      Header: 'Criação',
      accessor: 'createdAt',
      Cell: ({ cell }: CellProps<ReportsResponse>) => {
        const value = new Date(cell.row.values.createdAt);
        return FormatDate(value, 'dd/MM/yyyy hh:mm:ss');
      },
    },
  ];
  return columns;
};
