/* eslint-disable no-unused-vars */
import { createContext } from 'react';

export interface DialogContextData {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  okAction: (...args: any) => void;
}

export default createContext<DialogContextData>({} as DialogContextData);
