import { AlertDialogHeader, ModalBody } from '@chakra-ui/react';
import styled from 'styled-components';

export const DialogHeader = styled(AlertDialogHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #ebf2f7;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
`;

export const DialogBody = styled(ModalBody)`
  margin: 16px 8px 0 8px;
  padding: 24px;

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 8px;
    margin-top: 12px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: solid 16px transparent;
    background-color: #f1f4f7;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    height: 16px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: #cbd5e0;

    &:hover {
      background-color: #c0cddb;
    }
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 24px 24px 24px;

  button {
    min-width: 120px;
  }
`;
