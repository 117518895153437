import {
  HStack,
  Tag as TagChakra,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  label: string;
  icon: any;
  colorScheme: string;
}

const Tag: React.FC<Props> = ({ label, icon: Icon, colorScheme }) => {
  return (
    <HStack spacing={4}>
      <TagChakra
        size="md"
        variant="outline"
        borderRadius="full"
        colorScheme={colorScheme}
      >
        <TagLeftIcon as={Icon} />
        <TagLabel>{label}</TagLabel>
      </TagChakra>
    </HStack>
  );
};

export default Tag;
