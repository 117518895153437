import React, { createContext, useContext, useMemo } from 'react';
import { AdapterCustomerService } from './adapter.customer.service';
import { FakerCustomerService } from './faker.customer.service';
import { CustomerContextData } from './types';

const CustomerContext = createContext<CustomerContextData>(
  {} as CustomerContextData,
);

const factoryCustomerService = (): CustomerContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterCustomerService();
    }
    return new FakerCustomerService();
  }
  return new AdapterCustomerService();
};

export const CustomerProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryCustomerService(), []);

  return (
    <CustomerContext.Provider value={service}>
      {children}
    </CustomerContext.Provider>
  );
};

export function useCustomer(): CustomerContextData {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomer must be used within an CustomerProvider');
  }

  return context;
}
