import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  IoBusinessOutline,
  IoDocumentOutline,
  IoWarningSharp,
} from 'react-icons/io5';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { CreditoIcon, SerasaIcon } from '../../styles/chakra/icons';
import { FeatureModel, ProductModel } from '../models';

export interface SideMenuOption extends FeatureModel {
  path: string;
  redirect: boolean;
}

export interface SideMenuCategory extends ProductModel {
  icon: ComponentWithAs<'svg', IconProps> | IconType;
  features: SideMenuOption[];
}

export const SideMenuData: SideMenuCategory[] = [
  {
    id: '1',
    name: 'Empresas',
    icon: IoBusinessOutline,
    disabled: false,
    features: [
      {
        name: 'Registro Empresas',
        path: '/empresas',
        disabled: false,
        redirect: false,
      },
      {
        name: 'Usuários',
        path: '/usuarios',
        disabled: false,
        redirect: false,
      },
    ],
  },
  {
    id: '2',
    name: 'Planos',
    icon: IoDocumentOutline,
    disabled: false,
    features: [
      {
        name: 'Registro Planos',
        path: '/planos',
        disabled: false,
        redirect: false,
      },
    ],
  },
  {
    id: '5',
    name: 'Relatórios',
    icon: HiOutlineDocumentReport,
    disabled: false,
    features: [
      {
        name: 'Últimos adicionados',
        path: '/relatorios',
        disabled: false,
        redirect: false,
      },
    ],
  },
  {
    id: '3',
    icon: CreditoIcon,
    name: 'Crédito',
    disabled: false,
    features: [
      {
        name: 'Relatório de Crédito',
        path: '/credito/relatorio-credito',
        disabled: false,
        redirect: false,
      },
      {
        name: 'Monitore',
        path: '/credito/monitore',
        disabled: false,
        redirect: false,
      },
    ],
  },
  {
    id: '4',
    name: 'Serasa',
    icon: SerasaIcon,
    disabled: false,
    features: [
      {
        name: 'Relatórios Pefin',
        path: '/serasa/pefin',
        disabled: false,
        redirect: false,
      },
    ],
  },
  {
    id: '6',
    name: 'Alerta',
    icon: IoWarningSharp,
    disabled: false,
    features: [
      {
        name: 'Alerta do Sistema',
        path: '/alerts',
        disabled: false,
        redirect: false,
      },
    ],
  },
];
