import { parseISO, format } from 'date-fns';

export const FormatDate = (value: Date, formatValue: string): string => {
  return format(parseISO(value.toISOString()), formatValue);
};

export const FormatPhone = (value: number): string => {
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{4,})(\d{4})$/, '($1) $2-$3');
};
