/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiData } from '../types';
import {
  AlertsBaseResponse,
  AlertsContextData,
  AlertsCreate,
  AlertsUpdate,
} from './types';

export class AdapterAlertsService implements AlertsContextData {
  async createAlert({
    name,
    description,
    href,
    status,
    expire,
  }: AlertsCreate): Promise<ApiData<AlertsBaseResponse>> {
    const create = {
      name,
      description,
      href,
      status,
      expire,
    };
    const { data } = await api.post<AlertsBaseResponse>('/alerts', create);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data,
    };
  }

  async updateAlert(
    alertId: string,
    { name, description, href, status, expire }: AlertsUpdate,
  ): Promise<ApiData<AlertsUpdate>> {
    const create = {
      name,
      description,
      href,
      status,
      expire,
    };
    const { data } = await api.patch<AlertsUpdate>(
      `/alerts/${alertId}`,
      create,
    );
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data,
    };
  }

  async getAllAlerts(): Promise<ApiData<AlertsBaseResponse[]>> {
    const { data } = await api.get<AlertsBaseResponse[]>('/alerts');
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data: [],
    };
  }

  async getAlertById(id: string): Promise<ApiData<AlertsBaseResponse>> {
    const { data } = await api.get<AlertsBaseResponse>(`/alerts/${id}`);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data,
    };
  }
}
