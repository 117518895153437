import { v4 as uuidv4 } from 'uuid';
import { CustomerEntity } from '../models';

const nowDate = new Date();

export const DataCustomer: CustomerEntity[] = [
  {
    id: '1',
    firstName: 'Valery',
    lastName: 'Oliveira',
    email: 'valery.oliveira@teddysolucoes.com',
    profileId: 'rw-profile',
    cellphone: 1142101726,
    phone: 11965891323,
    status: 'Ativo',
    date: nowDate,
  },
  {
    id: '2',
    firstName: 'Silvia',
    lastName: 'Linares',
    email: 'silvia.linares@teddysolucoes.com',
    profileId: 'rw-profile',
    cellphone: 1142101726,
    phone: 11965891323,
    status: 'Ativo',
    date: nowDate,
  },
  {
    id: '3',
    firstName: 'Giovanna',
    lastName: 'Godinho',
    email: 'giovanna.godinho@teddysolucoes.com',
    profileId: 's-request-credit-analyze',
    cellphone: 1142101726,
    phone: 11965891323,
    status: 'Inativo',
    date: nowDate,
  },
];
