import { v4 as uuidv4 } from 'uuid';
import { GroupPermissionEntity } from '../models';

export const DataGroupPermissions: GroupPermissionEntity[] = [
  {
    id: uuidv4(),
    name: 'Crédito',
    permissions: [
      { id: '1', name: 'Relatório de Crédito' },
      { id: '2', name: 'Monitore' },
      { id: '3', name: 'Últimos Relatórios' },
    ],
  },
  {
    id: uuidv4(),
    name: 'Serasa',
    permissions: [
      { id: '1', name: 'Consulta Serasa' },
      { id: '1', name: 'Pefin' },
    ],
  },
  {
    id: uuidv4(),
    name: 'Configurações',
    permissions: [
      { id: '1', name: 'Usuários' },
      { id: '2', name: 'Perfis' },
    ],
  },
];

export const DataGroupPermissionsV2: GroupPermissionEntity[] = [
  {
    id: uuidv4(),
    name: 'Crédito',
    permissions: [{ id: '1', name: 'Relatório de Crédito' }],
  },
  {
    id: uuidv4(),
    name: 'Serasa',
    permissions: [{ id: '1', name: 'Consulta Serasa' }],
  },
  {
    id: uuidv4(),
    name: 'Configurações',
    permissions: [{ id: '1', name: 'Usuários' }],
  },
];
