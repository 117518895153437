/* eslint-disable react/no-children-prop */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-nested-ternary */
import React, { createRef, useCallback, useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Input,
  InputLeftAddon,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  useTable,
  useSortBy,
  Column,
  usePagination,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  Row,
  IdType,
} from 'react-table';
import Lottie, { Options as OptionsLottie } from 'react-lottie';
import { matchSorter } from 'match-sorter';
import * as loadingAnimation from '../../assets/animations/loading-table.json';
import EmptyIcon from '../../assets/svg/no-search-result-gray.svg';
import { Container, EmptyContainer, PageButton, Pagination } from './styles';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
  tablePageSize?: number;
  loading?: boolean;
};

function DataTable<Data extends object>({
  data,
  columns,
  tablePageSize = 5,
  loading = false,
}: DataTableProps<Data>) {
  const [filter, setFilter] = useState<string | undefined>('');
  const [pagination, setPagination] = useState<number[]>([]);

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }: any) {
    const count = preFilteredRows.length;

    return (
      <>
        <Input
          variant="flushed"
          colorScheme="gray"
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder="Filtro"
        />
      </>
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }: any) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i as React.Key} value={option as string}>
            {option as string}
          </option>
        ))}
      </select>
    );
  }

  function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
    return matchSorter(rows, filterValue, {
      keys: [(row: any) => row.values[id]],
    });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val: any) => !val;

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );
  const ourGlobalFilterFunction = useCallback(
    (rows: Row<Data>[], ids: IdType<Data>[], query: string) => {
      return matchSorter(rows, query, {
        keys: columns.map(column => `values.${column.accessor}`),
      });
    },
    [columns],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      globalFilter: ourGlobalFilterFunction,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: tablePageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  useEffect(() => {
    setPagination([]);
    const array = [];
    for (
      let index = 1;
      index <= Math.ceil(data.length / tablePageSize);
      index++
    ) {
      array.push(index);
    }
    setPagination([...array]);
  }, [data]);

  useEffect(() => {
    setGlobalFilter(filter); // Set the Global Filter to the filter prop.
  }, [filter, setGlobalFilter]);

  const animationData = loadingAnimation as any;

  const defaultOptions: OptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container>
      <th
        style={{
          display: 'flex',
          width: '100%',
          marginBottom: '8px',
          marginTop: '8px',
        }}
      >
        <InputGroup>
          <InputLeftAddon children={<SearchIcon color="gray.300" />} />
          <Input
            width="100%"
            variant="outline"
            colorScheme="gray"
            value={filter}
            onChange={e => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder="Filtro"
          />
        </InputGroup>
      </th>
      <div style={{ minHeight: '100px' }}>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <>
                      {column.render('Header')}
                      <chakra.span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {/* {headerGroups.map(headerGroup => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Th {...column.getHeaderProps()}>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </Th>
            ))}
          </Tr>
        ))} */}
          {!loading && (
            <Tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <>
                    <Tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                      ))}
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          )}
        </Table>
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              width: '100%',
            }}
          >
            <Lottie options={defaultOptions} height={160} width={160} />
          </div>
        )}
        {!loading && data.length === 0 && (
          <EmptyContainer>
            <img src={EmptyIcon} alt="Icone de Vazio" />
            <div>
              <h3>Nada encontrado</h3>
              <p>Tente recarregar a página</p>
            </div>
          </EmptyContainer>
        )}
      </div>
      {!loading && pageCount > 1 && (
        <Pagination>
          <PageButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </PageButton>
          {pagination
            .filter(
              (item, index) =>
                index >= 0 && index <= pageIndex + 4 && index >= pageIndex - 2,
            )
            .map(paginationPage => (
              <PageButton
                colorScheme={
                  pageIndex + 1 === paginationPage ? 'primary' : 'gray'
                }
                onClick={() => gotoPage(paginationPage - 1)}
              >
                {paginationPage}
              </PageButton>
            ))}
          <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </PageButton>
        </Pagination>
      )}
    </Container>
  );
}

export default DataTable;
