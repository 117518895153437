import Keycloak from 'keycloak-js';

export const keycloakClient = Keycloak({
  url: process.env.REACT_APP_AUTH_URL,
  realm: process.env.REACT_APP_AUTH_REALM as string,
  clientId: process.env.REACT_APP_AUTH_CLIENTID as string,
});

export const keycloakInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
};
