import { ReportModel } from '../models';

const nowDate = new Date();

export const DataReport: ReportModel[] = [
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
];
