import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h3`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--rc-color-primary);
  font-weight: bold;
  font-size: 14px;
  /* text-transform: uppercase; */

  svg {
    color: var(--rc-color-primary);
  }
`;
