/* eslint-disable react/no-children-prop */
import {
  FormControl,
  FormErrorMessage,
  SelectProps,
  Select,
  FormLabel,
} from '@chakra-ui/react';
import { Field } from 'formik';
import React from 'react';
import { Skeleton } from '..';
import { getErrorUtils } from '../../utils/error';

interface ChakraSelectProps extends SelectProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const ChakraSelect: React.FC<ChakraSelectProps> = ({
  name,
  label,
  placeholder,
  children,
  disabled = false,
  isLoading = false,
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        return (
          <FormControl
            isInvalid={
              getErrorUtils(form.errors, name) &&
              getErrorUtils(form.touched, name)
            }
          >
            <FormLabel>{label}</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <Select
                id={name}
                focusBorderColor="secondary.200"
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                {...rest}
              >
                {children}
              </Select>
            </Skeleton>
            <FormErrorMessage>
              {getErrorUtils(form.errors, name)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default ChakraSelect;
