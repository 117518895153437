import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 285px);
  position: relative;
  margin-left: auto;
  right: 0;
  transition-property: width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &.fullWidth {
    width: 100%;
  }
`;

export const Main = styled(motion.div)`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  right: 0;
  gap: 24px;
  min-height: 100vh;
  height: 100%;
  min-width: 360px;
  width: 100%;
  padding: 124px 16px 24px 24px;
  border-radius: 48px 0 0 0px;
  background-color: #fff;
  z-index: 100;

  @media (max-width: 556px) {
    padding: 90px 4px 24px 4px;
  }
`;

export const Content = styled.div`
  display: flex;
  @media (max-width: 1094px) {
    flex-direction: column;
  }
`;
