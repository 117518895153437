import { IconButton as IconButtonChakra, Tooltip } from '@chakra-ui/react';
import React from 'react';

interface Props {
  label: string;
  disabled?: boolean;
  icon:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  onClick: () => void;
}

const IconButton: React.FC<Props> = ({
  label,
  disabled = false,
  icon: Icon,
  onClick,
}) => {
  return (
    <Tooltip label={label} hasArrow fontSize="md" isDisabled={disabled}>
      <IconButtonChakra
        aria-label={label}
        variant="link"
        icon={Icon}
        isDisabled={disabled}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default IconButton;
