import { Stack, Select } from '@chakra-ui/react';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';

interface FilterValues {
  id: string;
  value: number;
  text: string,
}

interface Props {
  setTime: React.Dispatch<SetStateAction<number>> 
}

const data: FilterValues[] = [
  {
    id: '0',
    value: 7,
    text: ' Última semana',
  },
  {
    id: '1',
    value: 2,
    text: 'Ontem',
  },
  {
    id: '2',
    value: 130,
    text: 'Últimos 4 mêses',
  }
];

const FilterSelect = ({ setTime }: Props) => {
  const [dataSelect, setDataSelect] = useState<FilterValues[]>([]);

  const setDataHandle = useCallback(
    () => {
      setDataSelect(data)
    },
    [],
  )

  useEffect(() => {
    setDataHandle()
  }, [])
  return (
    <Stack spacing={10} direction="row">
      <Select
        width="25%"
        borderColor="#eb6625"
        fontSize="1rem"
        iconColor="orange.500"
        size="sm"
        onChange={e => {
          setTime(Number(e.target.value));
        }}
        placeholder="Periodo"
      >
        {dataSelect.length
          ? dataSelect.map(period => {
            const {id, value, text} = period;
              return (
                <option key={id} value={value}>
                  {text}
                </option>
              );
            })
          : null}
      </Select>
    </Stack>
  );
};

export default FilterSelect;
