import React, { createContext, useContext, useMemo } from 'react';
import { AdapterSerasaService } from './adapter.serasa.service';
import { FakerSerasaService } from './faker.serasa.service';
import { SerasaContextData } from './types';

const SerasaContext = createContext<SerasaContextData>({} as SerasaContextData);

const factorySerasaService = (): SerasaContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterSerasaService();
    }
    return new FakerSerasaService();
  }
  return new AdapterSerasaService();
};

export const SerasaProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factorySerasaService(), []);

  return (
    <SerasaContext.Provider value={service}>{children}</SerasaContext.Provider>
  );
};

export function useSerasa(): SerasaContextData {
  const context = useContext(SerasaContext);

  if (!context) {
    throw new Error('useSerasa must be used within an SerasaProvider');
  }

  return context;
}
