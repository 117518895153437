import * as yup from 'yup';

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

export default yup.object().shape({
  firstName: yup.string().required('Informe o Nome'),
  lastName: yup.string().required('Informe o Sobrenome'),
  email: yup
    .string()
    .email('Informe um E-mail válido')
    .required('Informe o E-mail'),
  password: yup
    .string()
    .min(8, 'Digite no Mínimo 8 Caracteres')
    .matches(lowercaseRegex, 'Digite no Mínimo 1 Caractere Minúsculo')
    .matches(uppercaseRegex, 'Digite no Mínimo 1 Caractere Maiúsculo')
    .matches(numericRegex, 'Digite no Mínimo 1 Caractere Númerico'),
  cellPhone: yup.string(),
  phone: yup.string(),
  status: yup.string().required('Informe o Status'),
});
