import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Report from './pages/report';

const Reports: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <Switch key={location.key}>
        <Route exact path="/relatorios" component={Report} />
        <Redirect to="/dashboard" />
      </Switch>
    </>
  );
};

export default Reports;
