import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  height: 100%;
  padding: 16px 16px;

  @media (max-width: 1094px) {
    width: 100%;
  }
`;
