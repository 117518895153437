export const LeftAnimation = {
  hidden: {
    opacity: 0,
    x: '8%',
  },
  visible: {
    opacity: 1,
    x: '0',
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.8, ease: 'easeIn' },
  },
};

export const RightAnimation = {
  hidden: {
    opacity: 0,
    x: '-8%',
  },
  visible: {
    opacity: 1,
    x: '0',
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.8, ease: 'easeIn' },
  },
};

export const LeftAnimationOpacity = {
  hidden: {
    x: '60%',
  },
  visible: {
    x: '0',
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
  exit: {
    x: '0',
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
};

export const FadeInAnimation = {
  hidden: {
    x: '-100%',
    scale: 1,
    opacity: 0,
  },
  visible: {
    x: '0',
    scale: 1,
    opacity: 1,
    transition: { duration: 0.8, ease: 'easeInOut' },
  },
  exit: {
    x: '-100%',
    opacity: 0,
    transition: { ease: 'easeIn' },
  },
};
