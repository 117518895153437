/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ProfileType {
  ADMIN = 'Admin',
  EDITOR = 'Editor',
}

export enum SituationType {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
}

export interface UserModel {
  id: string;
  name: string;
  email: string;
  user: string;
  cpf: number;
  cnpj: number;
  cellphone: number;
  landline: number;
  profile: ProfileType;
  situation: SituationType;
  date: Date;
}
