/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useToast } from '@chakra-ui/react';

import { BoxContext } from '../../../../contexts';

import { AnimatedContainer, Box, DataTable } from '../../../../components';

import { Columns } from './config';
import { useConfig } from '../../../../services/config';
import { ListUsersResponse } from '../../../../services/config/types';

const UserList: React.FC = () => {
  const [dataTable, setDataTable] = useState<ListUsersResponse[]>([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const { listUsers } = useConfig();
  const toast = useToast();

  const fetchUsers = useCallback(async () => {
    setLoadingTable(true);
    try {
      const response = await listUsers();
      if (response.data) {
        setDataTable(response.data);
      } else {
        setDataTable([]);
      }
      setLoadingTable(false);
    } catch {
      setDataTable([]);
      setLoadingTable(false);
      toast({
        title: 'Falha ao Buscar Usuários',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <AnimatedContainer>
      <Helmet title="Usuários - RiskCred by Teddy®" />
      <BoxContext.Provider
        value={{
          reloadAction: fetchUsers,
          titleBreadcrumb: 'Início',
          pathBreadcrumb: '/dashboard',
        }}
      >
        <Box title="Usuários" reloadButton breadcrumbs>
          <DataTable
            columns={Columns()}
            data={dataTable}
            loading={loadingTable}
          />
        </Box>
      </BoxContext.Provider>
    </AnimatedContainer>
  );
};

export default UserList;
