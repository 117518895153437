import { v4 as uuidv4 } from 'uuid';
import { CompanyEntity } from '../models';

const nowDate = new Date();

export const DataCompany: CompanyEntity[] = [
  {
    id: uuidv4(),
    name: 'Aços Motta',
    cnpj: '45583383000106',
    date: nowDate,
    vaduToken: '12345',
    planId: '451231321',
    profiles: [
      { id: 'rw-profile', name: 'Admin' },
      { id: 's-request-credit-analyze', name: 'Analista' },
    ],
  },
  {
    id: uuidv4(),
    name: 'BTG Pactual',
    cnpj: '29793323000161',
    date: nowDate,
    vaduToken: '12345',
    profiles: [
      { id: 'rw-profile', name: 'Admin' },
      { id: 's-request-credit-analyze', name: 'Analista' },
    ],
  },
  {
    id: uuidv4(),
    name: 'BS2',
    cnpj: '49920685000183',
    date: nowDate,
    profiles: [
      { id: 'rw-profile', name: 'Admin' },
      { id: 's-request-credit-analyze', name: 'Analista' },
    ],
  },
];
