/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable react/no-children-prop */
import {
  ComponentWithAs,
  IconProps,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';
import { FaMoneyBill } from 'react-icons/fa';
import { Skeleton } from '..';
import { Container, Label } from './styles';

interface ChakraInputProps extends InputProps {
  label: string;
  value?: string;
  icon: ComponentWithAs<'svg', IconProps> | IconType;
  type?: string;
  money?: boolean;
  number?: boolean;
  phone?: boolean;
  isLoading?: boolean;
}

const ViewData: React.FC<ChakraInputProps> = ({
  label,
  value = 'Desconhecido',
  icon: Icon,
  type = 'text',
  money = false,
  isLoading = false,
  ...rest
}) => {
  return (
    <Container>
      <Label>
        {money ? <FaMoneyBill /> : <Icon />}
        {label}
      </Label>
      <Skeleton isLoaded={!isLoading}>
        <InputGroup>
          <Input
            type={type}
            value={value}
            variant="flushed"
            style={{ borderBottom: 'none' }}
            disabled
            ml={6}
            {...rest}
          />
        </InputGroup>
      </Skeleton>
    </Container>
  );
};

export default ViewData;
