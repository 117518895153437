import { AccordionButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--rc-color-secondary);
  padding: 36px 0 8px 0;
  color: #fff;

  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent,
      var(--rc-color-secondary)
    );
    height: 16%;
    width: 245px;
    pointer-events: none;
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 276px;
  height: 100%;
  padding: 24px 36px 72px 36px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: solid 16px transparent;
    background-color: var(--rc-color-secondary);
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    height: 16px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

export const LogoCompany = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 242px;
  padding: 8px;
  background-color: #fff;
  border-radius: 0 32px 32px 0;
  position: relative;
  left: 0;

  img {
    height: 74px;
  }
`;

export const LogoTeddy = styled.div`
  display: flex;
  flex-direction: column;
  width: 205px;
  padding: 24px 0;
  margin: 0 36px;
  border-bottom: 1px solid #fff;
  /* background: red; */

  a {
    max-width: 90px;
    img {
      filter: brightness(0) invert(1);
    }
  }

  span {
    font-size: 12px;
  }
`;

interface CategoryProps {
  disabled: boolean;
}

export const CollapseButton = styled(AccordionButton)`
  display: flex;
  justify-content: space-between;

  &:focus {
    box-shadow: 0 0 0 3px rgba(224, 164, 67, 0.6);
  }
`;

export const Category = styled.div<CategoryProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: ${props => (props.disabled ? 'none' : 'unset')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'unset')};
  /* transition: all 0.2s ease-in-out; */

  div {
    display: flex;
    gap: 8px;
    svg {
      width: 22px;
      height: 22px;
    }
    h2 {
      cursor: text;
      font-size: 20px;
      font-weight: bold;
    }
  }

  &.active-router {
  }
`;

export const Options = styled.ul<CategoryProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  left: 16px;
  list-style: none;
  color: ${props => (props.disabled ? '#CBD5E0' : 'unset')};
  transition: all 0.2s ease-in-out;

  &:hover > li {
    opacity: ${props => (props.disabled ? '1' : '0.45')};
  }
`;

export const Option = styled(Link)<CategoryProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  padding: 4px 0 4px 30px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};
  opacity: ${props => (props.disabled ? '0.45' : '1')} !important;

  &::before {
    content: '';
    position: absolute;
    left: 16px;
    top: 10px;
    width: 8px;
    height: 8px;
    background-color: ${props =>
      props.disabled ? '#CBD5E0' : 'var(--rc-color-primary)'};
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  &:hover,
  &:active {
    padding-left: ${props => (props.disabled ? '16px' : '38px')};
    opacity: ${props => (props.disabled ? '0.45' : '1')} !important;

    &::before {
      margin-left: ${props => (props.disabled ? 'unset' : '8px')};
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
  }

  &.active-router {
    background-color: #333;
  }
`;

export const OptionLink = styled.a<CategoryProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  padding: 4px 0 4px 30px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};
  opacity: ${props => (props.disabled ? '0.45' : '1')} !important;

  &::before {
    content: '';
    position: absolute;
    left: 16px;
    top: 10px;
    width: 8px;
    height: 8px;
    background-color: ${props =>
      props.disabled ? '#CBD5E0' : 'var(--rc-color-primary)'};
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  &:hover,
  &:active {
    padding-left: ${props => (props.disabled ? '16px' : '38px')};
    opacity: ${props => (props.disabled ? '0.45' : '1')} !important;

    &::before {
      margin-left: ${props => (props.disabled ? 'unset' : '8px')};
    }
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
  }

  &.active-router {
    background-color: #183567;
    pointer-events: none;
  }
`;
