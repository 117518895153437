/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import {
  adapterProfileParse,
  getAllPermissions,
  parseIdToProfile,
  parsePermissionsCodeToGroupPermissions,
} from '../profile/profileUtils';
import { ApiData, ApiStatus } from '../types';
import { DataPlan } from './data';
import { GroupPermissionEntity, PlanEntity } from './models';
import { PlanContextData, PlanRequest, EditPlanRequest } from './types';

export class AdapterPlanService implements PlanContextData {
  async getPermissions(): Promise<ApiData<GroupPermissionEntity[]>> {
    return {
      error: false,
      data: await getAllPermissions(),
    };
  }

  async getPlan(id: string): Promise<ApiData<PlanEntity>> {
    const { data } = await api.get<{
      id: string;
      name: string;
      maxUsers: number;
      permissions: string[];
      profilesId: string[];
      createdAt: Date;
    }>(`/plans/${id}`);

    const groupPermissions = await parsePermissionsCodeToGroupPermissions(
      data.permissions,
    );
    // const profiles = await adapterProfileParse(data.profiles);
    return {
      error: false,
      data: {
        id: data.id,
        name: data.name,
        groupPermissions,
        maxUsers: data.maxUsers,
        date: data.createdAt,
        profilesId: data.profilesId,
      },
    };
  }

  deletePlan(id: string): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }

  async editPlan(
    id: string,
    { name, maxUsers, groupPermissions }: EditPlanRequest,
  ): Promise<ApiStatus> {
    if (groupPermissions) {
      const permissions = groupPermissions
        .map(vl => {
          return vl.permissions.map(permission => permission.id);
        })
        .join(',')
        .split(',')
        .filter(per => per !== '');
      const response = await api.patch(`/plans/${id}`, {
        name,
        maxUsers,
        permissions,
      });
      if (response) {
        return {
          error: false,
        };
      }
    }
    return {
      error: true,
    };
  }

  async newPlan({
    groupPermissions,
    maxUsers,
    name,
  }: PlanRequest): Promise<ApiStatus> {
    await api.post('/plans', {
      name,
      maxUsers,
      permissions: groupPermissions
        .map(vl => {
          return vl.permissions.map(permission => permission.id);
        })
        .join(',')
        .split(',')
        .filter(per => per !== ''),
    });
    return {
      error: false,
    };
  }

  async listPlans(): Promise<ApiData<PlanEntity[]>> {
    const { data: plans } = await api.get<
      {
        name: string;
        permissions: string[];
        profilesId: string[];
        id: string;
        maxUsers: number;
        createdAt: string;
        updatedAt: string;
      }[]
    >('/plans');

    const parsedData = await Promise.all(
      plans.map(
        async ({ id, maxUsers, name, permissions, profilesId, createdAt }) => ({
          id,
          name,
          maxUsers,
          groupPermissions: await parsePermissionsCodeToGroupPermissions(
            permissions,
          ),
          date: new Date(createdAt),
          profilesId,
        }),
      ),
    );

    return {
      error: false,
      data: parsedData,
    };
  }
}
