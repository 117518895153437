export const getErrorUtils = function (errors: any, inputName: string) {
  const parsedInputName = inputName.split('.');
  function getErrorObject(error: any, names: string[]): any {
    const [name, ...others] = names;

    if (!error) {
      return undefined;
    }

    if (names.length === 1) {
      return error[name];
    }
    return getErrorObject(error[name], others);
  }

  return getErrorObject(errors, parsedInputName);
};
