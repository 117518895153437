import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.header)`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  background: #fff;
  padding: 36px 36px 24px 36px;
  border-radius: 48px 0 0 0px;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 285px);
  z-index: 1000;
  transition-property: padding, border-radius, width;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &.sticky {
    border-radius: 0;
    border-bottom: 3px solid #ebf2f7;
    /* box-shadow: 0px 5px 25px #52575d1a; */
  }

  &.fullWidth {
    border-radius: 0;
    /* border-bottom: 3px solid #ebf2f7; */
    width: 100%;
  }

  @media (max-width: 556px) {
    padding: 24px 24px 24px 24px;
  }
`;

export const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
`;

export const PlatformName = styled.h1`
  display: flex;
  align-items: center;
  font-size: 38px;
  font-weight: bold;
  color: var(--rc-color-primary);
  transition: all 0.4s ease-out;

  span {
    position: relative;
    bottom: -5px;
    font-size: 24px;
    color: var(--rc-color-secondary);
  }

  .menu-button {
    display: none;
  }

  @media (max-width: 996px) {
    font-size: 32px;

    span {
      bottom: -5px;
      font-size: 16px;
    }
    .menu-button {
      display: unset;
    }
  }

  @media (max-width: 556px) {
    font-size: 24px;

    span {
      bottom: -2px;
      font-size: 16px;
    }
  }
`;

export const Welcome = styled.h2`
  font-size: 24px;
  font-weight: 400;
  transition: all 0.4s ease-out;
  line-height: 18px;
  text-align: center;
  position: relative;
  bottom: -5px;
  strong {
    font-weight: bold;
    color: var(--rc-color-primary);
  }

  &.sticky {
    font-size: 20px;
  }

  @media (max-width: 1498px) {
    font-size: 18px;

    &.sticky {
      font-size: 18px;
    }
  }

  @media (max-width: 996px) {
    display: none;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    display: flex;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 10px;
    border: 2px solid var(--rc-color-primary);
    background-color: #fff;
    img {
      width: 100%;
    }
  }

  h4 {
    font-size: 16px;
    color: #2e4765;
    opacity: 0.5;
    white-space: nowrap;
    @media (max-width: 645px) {
      display: none;
    }
  }
`;

export const ProfileDropdown = styled.div`
  display: flex;
  align-items: center;
`;
