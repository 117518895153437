/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { MdMoreHoriz, MdPowerSettingsNew } from 'react-icons/md';
import { HamburgerIcon, SettingsIcon } from '@chakra-ui/icons';
import { useKeycloak } from '@react-keycloak/web';
import { Link } from 'react-router-dom';
import {
  Container,
  PlatformContainer,
  PlatformName,
  ProfileContainer,
  ProfileDropdown,
  ProfileInfo,
} from './styles';
import LogoTeddyOpenBanking from '../../assets/svg/teddy-open-banking.svg';
import { LeftAnimationOpacity } from '../../utils/variants';
import { MenuContext } from '../../contexts';
import { useAuth } from '../../hooks/auth';

interface User extends Keycloak.KeycloakTokenParsed {
  name: string;
  email: string;
}

const Header: React.FC = () => {
  const { keycloak, initialized } = useKeycloak();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isSticky, setSticky] = useState(false);
  const { isOpen, setIsOpen } = useContext(MenuContext);
  const { logout } = useAuth();
  const [myUser, setMyUser] = useState<string | undefined>();

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      const data: User = keycloak.idTokenParsed as User;
      setMyUser(data.name);
    }
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setSticky(currentScrollPos > 12);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [prevScrollPos, isSticky, handleScroll]);

  return (
    <Container
      variants={LeftAnimationOpacity}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`${isSticky ? 'sticky' : 'unset'} ${
        !isOpen ? 'fullWidth' : 'unset'
      } `}
    >
      <PlatformContainer>
        <PlatformName className={`${isSticky ? ' sticky' : 'unset'}`}>
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            colorScheme="primary"
            variant="outline"
            mr={4}
            className="menu-button"
            onClick={() => setIsOpen(!isOpen)}
          />
          RiskCred<span>Admin</span>
        </PlatformName>
        <ProfileContainer>
          <ProfileInfo>
            <div>
              <img src={LogoTeddyOpenBanking} alt="Teddy Open Banking" />
            </div>
            <h4>{myUser || 'Carregando...'}</h4>
          </ProfileInfo>
          <ProfileDropdown>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Opções"
                icon={<MdMoreHoriz />}
                size="xs"
                variant="outline"
                placement="bottom-end"
              />
              <MenuList>
                <Link to="/config/perfil">
                  <MenuItem icon={<SettingsIcon />}>
                    Configurações da Conta
                  </MenuItem>
                </Link>
                <MenuItem
                  icon={<MdPowerSettingsNew />}
                  onClick={() => logout()}
                >
                  Sair do Sistema
                </MenuItem>
              </MenuList>
            </Menu>
          </ProfileDropdown>
        </ProfileContainer>
      </PlatformContainer>
    </Container>
  );
};

export default Header;
