/* eslint-disable react/require-default-props */
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Tag } from '..';

import {
  BreakLineContainer,
  Container,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from './styles';

interface Props {
  title: string;
  description: string;
}

const InfoBadge: React.FC<Props> = ({ title, description }) => {
  const { isOpen, onClose } = useDisclosure();
  return (
    <Container>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        motionPreset="slideInBottom"
        isCentered
        closeOnEsc
        closeOnOverlayClick
        scrollBehavior="inside"
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <DialogHeader>
            <h3>{title}</h3>
          </DialogHeader>
          <ModalCloseButton />
          <DialogBody>
            <BreakLineContainer>{description}</BreakLineContainer>
          </DialogBody>
          <DialogFooter />
        </ModalContent>
      </Modal>
      <Tag label="Informações" colorScheme="primary" icon={FaQuestionCircle} />
    </Container>
  );
};

export default InfoBadge;
