import React from 'react';
import Lottie, { Options as OptionsLottie } from 'react-lottie';
import * as loadingAnimation from '../../assets/animations/loading-table.json';
import { Container } from './styles';

const animationData = loadingAnimation as any;

const defaultOptions: OptionsLottie = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface LoadingAnimationProps {
  options?: OptionsLottie;
  height?: number | string;
  width?: number | string;
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  options = defaultOptions,
  width = 160,
  height = 160,
  ...rest
}) => {
  return (
    <Container>
      <Lottie options={options} height={height} width={width} {...rest} />
    </Container>
  );
};

export default LoadingAnimation;
