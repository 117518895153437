import { motion } from 'framer-motion';
import React from 'react';
import Lottie, { Options as OptionsLottie } from 'react-lottie';
import * as loadingAnimation from '../../assets/animations/loading-table.json';
import RiskCredLogo from '../../assets/svg/risk-cred-logo-white.svg';
import { Container } from './styles';

const Preloader: React.FC = () => {
  const animationData = loadingAnimation as any;
  const defaultOptions: OptionsLottie = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container>
      <motion.img src={RiskCredLogo} alt="RiskCred by Teddy" />
      <p>Aguarde um momento...</p>
      <Lottie options={defaultOptions} height={120} width={120} />
    </Container>
  );
};

export default Preloader;
