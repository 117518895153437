/* eslint-disable no-return-await */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useToast } from '@chakra-ui/react';
import {
  AnimatedContainer,
  Box,
  DataTable,
  FilterSelect,
} from '../../../../components';
import { BoxContext } from '../../../../contexts';
import { Columns } from './config';
import { ReportsResponse } from '../../../../services/reports/types';
import { useReports } from '../../../../services/reports';

const Report: React.FC = () => {
  const [dataTable, setDataTable] = useState<ReportsResponse[]>([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const { lastestReports } = useReports();
  const toast = useToast();
  const [time, setTime] = useState(30);

  const fetchReports = useCallback(async () => {
    setLoadingTable(true);
    try {
      const response = await lastestReports(Number(time));
      if (response.data) {
        setDataTable(response.data);
      } else {
        setDataTable([]);
      }
      setLoadingTable(false);
    } catch {
      setDataTable([]);
      setLoadingTable(false);
      toast({
        title: 'Falha ao Buscar Relatórios',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [toast, time]);

  useEffect(() => {
    fetchReports();
  }, [time]);

  return (
    <AnimatedContainer>
      <Helmet title="Relatórios - RiskCred by Teddy®" />
      <BoxContext.Provider
        value={{
          reloadAction: fetchReports,
          titleBreadcrumb: 'Início',
          pathBreadcrumb: '/dashboard',
        }}
      >
        <Box title="Relatórios" reloadButton breadcrumbs>
          <FilterSelect setTime={setTime} />
          <DataTable
            columns={Columns()}
            data={dataTable}
            loading={loadingTable}
            tablePageSize={8}
          />
        </Box>
      </BoxContext.Provider>
    </AnimatedContainer>
  );
};

export default Report;
