/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { CheckCircleIcon, EditIcon, WarningIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { CellContainer } from '../../../../styles/global';
import { FormatPhone } from '../../../../utils';
import { ListUsersResponse } from '../../../../services/config/types';
import { IconButton, Tag } from '../../../../components';

export const Columns = () => {
  const history = useHistory();
  const columns: Column<ListUsersResponse>[] = [
    {
      Header: 'Nome Completo',
      Cell: ({ cell }: CellProps<ListUsersResponse>) =>
        `${cell.row.original.firstName} ${cell.row.original.lastName}`,
    },
    {
      Header: 'Empresa',
      accessor: 'company',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Cell: ({ cell }: CellProps<ListUsersResponse>) => (
        <a href={`mailto:${cell.row.values.email}`}>{cell.row.values.email}</a>
      ),
    },
    {
      Header: 'Telefone',
      accessor: 'phone',
      Cell: ({ cell }: any) => {
        return cell.row.values.phone ? (
          <a href={`tel:${cell.row.values.phone}`}>
            {FormatPhone(cell.row.values.phone)}
          </a>
        ) : (
          'Nenhum'
        );
      },
    },
    {
      Header: 'Celular',
      accessor: 'cellPhone',
      Cell: ({ cell }: CellProps<any>) => {
        const { cellPhone } = cell.row.values;
        return cellPhone ? (
          <a href={`tel:${cellPhone}`}>
            {FormatPhone(cellPhone)}
          </a>
        ) : (
          'Nenhum'
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cell }: any) => (
        <CellContainer>
          {cell.row.values.status === 'Ativo' ? (
            <Tag label="Ativo" colorScheme="success" icon={CheckCircleIcon} />
          ) : (
            <Tag label="Inativo" colorScheme="red" icon={WarningIcon} />
          )}
        </CellContainer>
      ),
    },
    {
      Header: 'Ação',
      Cell: ({ cell }: any) => (
        <CellContainer>
          <IconButton
            label="Editar Usuário"
            icon={<EditIcon />}
            onClick={() => {
              history.push(`/usuarios/editar/${cell.row.original.id}`);
            }}
          />
        </CellContainer>
      ),
    },
  ];
  return columns;
};
