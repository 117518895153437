import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 16px;
  width: 250px;
  /* overflow-y: auto; */

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: solid 16px transparent;
    background-color: #f9f9f9;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    height: 16px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: #dddddd;

    &:hover {
      background-color: #c9c9c9;
    }
  }
`;
export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7fafc;
  border-radius: 16px;
  padding: 14px;

  h3 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--rc-color-primary);
  }

  div {
    height: 160px;
  }
`;
