import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body{
    scroll-behavior: smooth;
    background: #FFF;
    color: #52575D;
    -webkit-font-smoothing: antialiased !important;
    overflow: -moz-scrollbars-vertical; 
    /* overflow-y: scroll; */
  }

  body, input, select, option, button{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: normal;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500;
  }

  button{
    cursor: pointer;
  }

  :root{
    --rc-color-primary: #EB6625;
    --rc-color-secondary: #000;
  }

  #root{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const DialogMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-top: 8px;
    font-weight: bold;
    font-size: 20px;
  }
`;
