import React from 'react';
import { AuthProvider } from '../hooks/auth';
import { CompanyProvider } from './company';
import { ConfigProvider } from './config';
import { CustomerProvider } from './customer';
import { PlanProvider } from './plan';
import { ProfileProvider } from './profile';
import { ReportsProvider } from './reports';
import { SerasaProvider } from './serasa';
import { AlertsProvider } from './alerts';

const ApiProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
     <AlertsProvider>
      <ProfileProvider>
        <ReportsProvider>
          <PlanProvider>
            <CustomerProvider>
              <CompanyProvider>
                <ConfigProvider>
                  <SerasaProvider>
                        {children}
                    </SerasaProvider>
                </ConfigProvider>
              </CompanyProvider>
            </CustomerProvider>
          </PlanProvider>
        </ReportsProvider>
      </ProfileProvider>
      </AlertsProvider>
    </AuthProvider>
  );
};

export default ApiProvider;
