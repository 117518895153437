/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiData, ApiStatus } from '../types';
import { DataCustomer } from './data';
import { CustomerEntity } from './models';
import {
  CustomerContextData,
  CustomerRequest,
  EditCustomerRequest,
  ListProfilesResponse,
} from './types';

export function parseToString(data: string) {
  const parsedData = data.replace(/[^\d]/g, '');
  return parsedData;
}

export class AdapterCustomerService implements CustomerContextData {
  async listProfiles(cnpj: string): Promise<ApiData<ListProfilesResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        name: string;
      }[]
    >(`/companies/${cnpj}/profiles`);

    if (data) {
      return {
        error: false,
        data,
      };
    }

    return {
      error: true,
    };
  }

  async getCustomer(
    cnpj: string,
    id: string,
  ): Promise<ApiData<CustomerEntity>> {
    const { data } = await api.get<{
      id: string;
      firstName: string;
      lastName: string;
      profileId: string;
      email: string;
      cnpj: string;
      phone: string;
      cellPhone: string;
      status: string;
      createdAt: Date;
    }>(`/users/${id}`);

    return {
      error: false,
      data: {
        id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        profileId: data.profileId,
        cellphone: Number(data.cellPhone),
        phone: Number(data.phone),
        status: data.status,
        date: data.createdAt,
      },
    };
  }

  deleteCustomer(id: string): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }

  async editCustomer(
    id: string,
    {
      firstName,
      lastName,
      email,
      profileId,
      cellphone,
      phone,
      status,
    }: EditCustomerRequest,
  ): Promise<ApiStatus> {
    const response = await api.patch(`/users/${id}`, {
      firstName,
      lastName,
      email,
      profileId,
      cellPhone: cellphone ? parseToString(String(cellphone)) : undefined,
      phone: phone ? parseToString(String(phone)) : undefined,
      status,
    });
    return {
      error: false,
    };
  }

  async newCustomer(
    cnpj: string,
    {
      email,
      firstName,
      lastName,
      password,
      profileId,
      phone,
      cellphone,
      status,
    }: CustomerRequest,
  ): Promise<ApiStatus> {
    const response = await api.post(`companies/${cnpj}/users`, {
      firstName,
      lastName,
      password,
      profileId,
      email,
      phone: String(phone),
      cellPhone: String(cellphone),
      status,
    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  async listCustomers(cnpj: string): Promise<ApiData<CustomerEntity[]>> {
    const { data } = await api.get<
      {
        id: string;
        firstName: string;
        lastName: string;
        profileId: string;
        email: string;
        cnpj: string;
        phone: string;
        cellPhone: string;
        status: string;
        createdAt: Date;
        updatedAt: Date;
      }[]
    >(`/companies/${cnpj}/users`);

    const profiles = await api.get<
      {
        id: string;
        name: string;
      }[]
    >(`/companies/${cnpj}/profiles`);

    return {
      error: false,
      data: data.map(
        ({
          cellPhone,
          createdAt,
          email,
          firstName,
          id,
          lastName,
          phone,
          profileId,
          status,
        }) => {
          const profileSelected = profiles.data.find(
            profile => profile.id === profileId,
          );
          if (profileSelected) {
            return {
              cellphone: Number(cellPhone),
              date: createdAt,
              email,
              firstName,
              id,
              lastName,
              phone: Number(phone),
              profileId: profileSelected.name,
              status,
            };
          }

          return {
            cellphone: Number(cellPhone),
            date: createdAt,
            email,
            firstName,
            id,
            lastName,
            phone: Number(phone),
            profileId,
            status,
          };
        },
      ),
    };
  }
}
