import { v4 as uuidv4 } from 'uuid';
import { PlanEntity } from '../models';
import {
  DataGroupPermissions,
  DataGroupPermissionsV2,
} from './groupPermission';

const nowDate = new Date();

export const DataPlan = [
  {
    id: '451231321',
    name: 'RiskCred Plus',
    groupPermissions: DataGroupPermissions,
    maxUsers: 15,
    date: nowDate,
    profiles: [
      {
        id: uuidv4(),
        name: 'Admin',
        groupPermissions: [
          {
            id: uuidv4(),
            name: 'Crédito',
            permissions: [
              { id: '1', name: 'Relatório de Crédito' },
              { id: '2', name: 'Monitore' },
              { id: '3', name: 'Últimos Relatórios' },
            ],
          },
          {
            id: uuidv4(),
            name: 'Serasa',
            permissions: [
              { id: '1', name: 'Consulta Serasa' },
              { id: '2', name: 'Últimos Relatórios' },
            ],
          },
          {
            id: uuidv4(),
            name: 'Configurações',
            permissions: [{ id: '1', name: 'Usuários' }],
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'RiskCred Business',
    groupPermissions: DataGroupPermissionsV2,
    maxUsers: 10,
    date: nowDate,
    profiles: [
      {
        id: uuidv4(),
        name: 'Analista',
        groupPermissions: [
          {
            id: '1',
            name: 'Crédito',
            permissions: [{ id: uuidv4(), name: 'Relatório de Crédito' }],
          },
          {
            id: '2',
            name: 'Serasa',
            permissions: [{ id: uuidv4(), name: 'Consulta Serasa' }],
          },
          {
            id: '3',
            name: 'Configurações',
            permissions: [{ id: uuidv4(), name: 'Usuários' }],
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'RiskCred Diamond',
    groupPermissions: DataGroupPermissions,
    maxUsers: 8,
    date: nowDate,
    profiles: [
      {
        id: uuidv4(),
        name: 'Admin',
        groupPermissions: [
          {
            id: '1',
            name: 'Crédito',
            permissions: [{ id: uuidv4(), name: 'Relatório de Crédito' }],
          },
          {
            id: '2',
            name: 'Serasa',
            permissions: [{ id: uuidv4(), name: 'Consulta Serasa' }],
          },
          {
            id: '3',
            name: 'Configurações',
            permissions: [{ id: uuidv4(), name: 'Usuários' }],
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'RiskCred Tester',
    groupPermissions: DataGroupPermissions,
    maxUsers: 5,
    date: nowDate,
    profiles: [
      {
        id: uuidv4(),
        name: 'Admin',
        groupPermissions: [
          {
            id: uuidv4(),
            name: 'Crédito',
            permissions: [
              { id: '1', name: 'Relatório de Crédito' },
              { id: '2', name: 'Monitore' },
              { id: '3', name: 'Últimos Relatórios' },
            ],
          },
          {
            id: uuidv4(),
            name: 'Serasa',
            permissions: [
              { id: '1', name: 'Consulta Serasa' },
              { id: '2', name: 'Últimos Relatórios' },
            ],
          },
          {
            id: uuidv4(),
            name: 'Configurações',
            permissions: [{ id: '1', name: 'Usuários' }],
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'RiskCred Delta',
    groupPermissions: DataGroupPermissions,
    maxUsers: 15,
    date: nowDate,
    profiles: [
      {
        id: uuidv4(),
        name: 'Admin',
        groupPermissions: [
          {
            id: uuidv4(),
            name: 'Crédito',
            permissions: [
              { id: '1', name: 'Relatório de Crédito' },
              { id: '2', name: 'Monitore' },
              { id: '3', name: 'Últimos Relatórios' },
            ],
          },
          {
            id: uuidv4(),
            name: 'Serasa',
            permissions: [
              { id: '1', name: 'Consulta Serasa' },
              { id: '2', name: 'Últimos Relatórios' },
            ],
          },
          {
            id: uuidv4(),
            name: 'Configurações',
            permissions: [{ id: '1', name: 'Usuários' }],
          },
        ],
      },
    ],
  },
];
