/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiData, ApiStatus } from '../types';
import { DataPefin } from './data';
import {
  ListAllPefinResponse,
  PefinRequest,
  PefinResponse,
  SerasaContextData,
} from './types';

export class AdapterSerasaService implements SerasaContextData {
  lastestReports(): Promise<PefinResponse> {
    throw new Error('Method not implemented.');
  }

  async getPefin(id: string): Promise<ApiData<ListAllPefinResponse>> {
    const { data } = await api.get<ListAllPefinResponse>(`/perfin/${id}`);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
    };
  }

  async findAllPefins(): Promise<ApiData<ListAllPefinResponse[]>> {
    const { data } = await api.get<ListAllPefinResponse[]>('/perfin');
    return {
      error: false,
      data,
    };
  }

  async deletePefin(id: string, status: boolean): Promise<ApiStatus> {
    const response = await api.patch<ListAllPefinResponse[]>(`/perfin/${id}`, {
      status,
    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  registerPefin(pefinRequest: PefinRequest): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }
}
