import React, { createContext, useContext, useMemo } from 'react';
import { AdapterCompanyService } from './adapter.company.service';
import { FakerCompanyService } from './faker.company.service';
import { CompanyContextData } from './types';

const CompanyContext = createContext<CompanyContextData>(
  {} as CompanyContextData,
);

const factoryCompanyService = (): CompanyContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterCompanyService();
    }
    return new FakerCompanyService();
  }
  return new AdapterCompanyService();
};

export const CompanyProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryCompanyService(), []);

  return (
    <CompanyContext.Provider value={service}>
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error('useCompany must be used within an CompanyProvider');
  }

  return context;
}
