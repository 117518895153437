/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiData, ApiStatus } from '../types';
import { TypeReport } from './models/type.enum';
import {
  Details,
  ReportsBaseResponse,
  ReportsContextData,
  ReportsMonitoreResponse,
  ReportsResponse,
  RequestBy,
} from './types';

export class AdapterReportsService implements ReportsContextData {
  async deleteMonitore(id: string): Promise<ApiStatus> {
    const response = await api.patch(`/credit/monitor/${id}`, {
      status: 'excluido',
    });

    if (response) {
      return {
        error: false,
      };
    }

    return {
      error: true,
    };
  }

  async doneMonitore(id: string): Promise<ApiStatus> {
    const response = await api.patch(`/credit/monitor/${id}`, {
      status: 'monitorado',
    });

    if (response) {
      return {
        error: false,
      };
    }

    return {
      error: true,
    };
  }

  async lastestCredit(
    daysAgo: number,
  ): Promise<ApiData<ReportsBaseResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        requestedBy: RequestBy;
        detailsId: string;
        details: Details;
        createdAt: Date;
        updatedAt: Date;
      }[]
    >('/reports/credit', { params: { daysAgo } });

    const companies = await api.get<
      {
        cnpj: string;
        name: string;
      }[]
    >('/companies');

    const result = data.map(
      ({ id, requestedBy, detailsId, details, createdAt, updatedAt }) => {
        return {
          id,
          document: details.cnpj || 'Desconhecido',
          user:
            `${requestedBy?.userId?.firstName} ${requestedBy?.userId?.lastName}` ||
            'Desconhecido',
          company:
            companies.data.find(company => company.cnpj === requestedBy.cnpj)
              ?.name || 'Desconhecido',
          createdAt,
        };
      },
    );

    if (data && result) {
      return {
        error: false,
        data: result,
      };
    }
    return {
      error: false,
      data: [],
    };
  }

  async lastestMonitore(
    daysAgo: number,
  ): Promise<ApiData<ReportsMonitoreResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        requestedBy: RequestBy;
        detailsId: string;
        details: Details;
        createdAt: Date;
        updatedAt: Date;
      }[]
    >('/reports/credit/monitore', { params: { daysAgo } });

    const companies = await api.get<
      {
        cnpj: string;
        name: string;
      }[]
    >('/companies');

    const result = data.map(
      ({ id, requestedBy, detailsId, createdAt, details, updatedAt }) => {
        return {
          id,
          document: details.monitored || 'Desconhecido',
          user:
            `${requestedBy?.userId?.firstName} ${requestedBy?.userId?.lastName}` ||
            'Desconhecido',
          company:
            companies.data.find(company => company.cnpj === requestedBy.cnpj)
              ?.name || 'Desconhecido',
          createdAt,
          detailsId,
          status: details.status || 'Desconhecido',
        };
      },
    );

    if (data && result.length) {
      console.log(result);

      return {
        error: false,
        data: result,
      };
    }
    return {
      error: false,
      data: [],
    };
  }

  async lastestReports(daysAgo: number): Promise<ApiData<ReportsResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        requestedBy: RequestBy;
        type: string;
        details: Details;
        detailsId: string;
        createdAt: Date;
        updatedAt: Date;
      }[]
    >('/reports', { params: { daysAgo } });
    const companies = await api.get<
      {
        cnpj: string;
        name: string;
      }[]
    >('/companies');

    const result = data.map(
      ({ id, requestedBy, type, detailsId, details, createdAt, updatedAt }) => {
        const cnpjSelected =
          type === TypeReport.MONITOR ? details.monitored : details.cnpj;
        return {
          id,
          document: cnpjSelected || 'Desconhecido',
          company:
            companies.data.find(company => company.cnpj === requestedBy?.cnpj)
              ?.name || 'Desconhecido',
          user:
            `${requestedBy?.userId?.firstName} ${requestedBy?.userId?.lastName}` ||
            'Desconhecido',
          type,
          createdAt,
        };
      },
    );

    if (data && result) {
      return {
        error: false,
        data: result,
      };
    }
    return {
      error: false,
      data: [],
    };
  }
}
