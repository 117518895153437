import * as React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useAuth } from '../hooks/auth';
import { LoadingAnimation } from '../components';

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteParams): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { hasToken } = useAuth();

  if (keycloak.authenticated && !hasToken) {
    return <LoadingAnimation />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        keycloak.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
