import { AlertDialogHeader } from '@chakra-ui/react';
import styled from 'styled-components';

export const DialogHeader = styled(AlertDialogHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #ebf2f7;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
`;

export const DialogBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  padding: 36px;
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px 24px 24px 24px;

  button {
    min-width: 120px;
  }
`;
