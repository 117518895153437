import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 5px 25px #52575d1a;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 3px solid #ebf2f7;

  h3 {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
`;

export const SpacerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .breadcrumb {
    @media (max-width: 556px) {
      display: none;
    }
  }
`;

export const RouterActive = styled.span`
  font-weight: bold;
  color: var(--rc-color-primary);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
