import React from 'react';

import { LeftAnimation } from '../../utils/variants';
import { Container } from './styles';

const AnimatedContainer: React.FC = ({ children }) => {
  return (
    <Container
      variants={LeftAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {children}
    </Container>
  );
};

export default AnimatedContainer;
